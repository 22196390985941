import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// TODO: How to surpass cyclical dependency linting errors cleanly?

import { store as user, UserStore, State as UserState } from '@/store/modules/user'

export type RootState = {
  user: UserState;
};

export type Store = UserStore<Pick<RootState, 'user'>>;

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

// Plug in session storage based persistence
plugins.push(createPersistedState({ storage: window.sessionStorage }))

export const store = createStore({
  plugins,
  modules: {
    user
  }
})

export function useStore (): Store {
  return store as Store
}
