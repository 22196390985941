
import { defineComponent } from 'vue'
import { useStore } from '@/store'
import { UserActionTypes } from '@/store/modules/user/action-types'

export default defineComponent({
  data () {
    return {
      identifier: '',
      password: ''
    }
  },

  setup () {
    const store = useStore()

    async function dispatchLogin (identifier: string, password: string) {
      return await store.dispatch(UserActionTypes.LOGIN, {
        identifier,
        password
      })
    }

    return {
      requestLogin: dispatchLogin
    }
  },
  methods: {
    async login () {
      const data = await this.requestLogin(this.identifier, this.password)
      if (data) {
        await this.$router.push({ name: 'home' })
      }
    }
  }

})
