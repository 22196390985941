import axios from 'axios'
import { ElMessage } from 'element-plus'
import { saveAs } from 'file-saver'
import { useStore } from '@/store'

const request = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 50000
})

request.interceptors.request.use(config => {
  const store = useStore()
  if (store.getters.jwt) {
    config.headers = {
      Authorization: `Bearer ${store.getters.jwt}`
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

request.interceptors.response.use(response => {
  const res = response.data
  if (response.request.responseType === 'blob') {
    saveAs(res, getFileName(response.headers['content-disposition']))
  } else {
    return res
  }
}, error => {
  ElMessage.error(error.message)
  return Promise.reject(error)
})

function getFileName (disposition: string, defaultName = '') {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(disposition)
  if (matches != null && matches[1]) {
    return decodeURIComponent(matches[1].replace(/['"]/g, ''))
  } else {
    return defaultName
  }
}

export default request
