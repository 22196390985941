import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@/store'

import { State } from './state'
import { Mutations } from './mutations'
import { UserMutationTypes } from './mutation-types'
import { UserActionTypes } from './action-types'
import { login } from '@/api/user'
import { UserAuth, UserProfile } from '@/@types'

type AugmentedActionContext = {
  commit<K extends keyof Mutations> (
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [UserActionTypes.LOGIN] (
    { commit }: AugmentedActionContext,
    {
      identifier,
      password
    }: UserAuth
  ): Promise<boolean>;

  [UserActionTypes.LOGOUT] (
    { commit }: AugmentedActionContext
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [UserActionTypes.LOGIN] ({ commit }, {
    identifier,
    password
  }) {
    try {
      const data = await login(identifier, password)
      commit(UserMutationTypes.SET_PROFILE, data.user as UserProfile)
      commit(UserMutationTypes.SET_AUTH, data.jwt as string)
      return true
    } catch (e) {
      return false
    }
  },
  [UserActionTypes.LOGOUT] ({ commit }) {
    commit(UserMutationTypes.SET_PROFILE, null)
    commit(UserMutationTypes.SET_AUTH, '')
  }

}
