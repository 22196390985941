import { GetterTree } from 'vuex'
import type { UserProfile } from '@/@types'
import { RootState } from '@/store'
import { State } from './state'

export type Getters = {
  profile (state: State): UserProfile | null;
  jwt (state: State): string;
}

export const getters: GetterTree<State, RootState> & Getters = {
  profile: (state) => state.profile,
  jwt: (state) => state.jwt
}
