import type { UserProfile } from '@/@types'

export type State = {
  profile: UserProfile | null;
  jwt: string;
}

export const state: State = {
  profile: {
    username: '',
    email: '',
    id: -1
  },
  jwt: ''
}
