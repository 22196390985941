import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/LoginView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'contact'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactsView.vue')
  },
  {
    path: '/asset',
    name: 'asset',
    component: () => import(/* webpackChunkName: "asset" */ '../views/AssetsView.vue')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import(/* webpackChunkName: "company" */ '../views/CompaniesView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
