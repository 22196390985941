import { MutationTree } from 'vuex'

import type { UserProfile } from '@/@types'

import { State } from './state'
import { UserMutationTypes as MutationTypes } from './mutation-types'

export type Mutations<S = State> = {
  [MutationTypes.SET_PROFILE] (state: S, payload: UserProfile | null): void;
  [MutationTypes.SET_AUTH] (state: S, payload: string): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_PROFILE] (state: State, profile: UserProfile | null) {
    state.profile = profile
  },
  [MutationTypes.SET_AUTH] (state: State, jwt: string) {
    state.jwt = jwt
  }
}
